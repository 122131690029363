import { z } from 'zod';

import { AccountSchema } from './account.interface';
import { SubdomainSchema } from './subdomain.interface';
import { WorkspaceSchema } from './workspace.interface';

export const GetWorkspaceAccessResponseDtoSchema = z.object({
  accountId: AccountSchema.shape._id,
  accountName: AccountSchema.shape.name,
  workspaceId: WorkspaceSchema.shape._id,
  userId: z.string(),
});
export interface IGetWorkspaceAccessResponseDto extends z.infer<typeof GetWorkspaceAccessResponseDtoSchema> {}

export const GetWorkspaceSwitcherResponseDtoSchema = GetWorkspaceAccessResponseDtoSchema.pick({
  accountId: true,
  accountName: true,
  workspaceId: true,
}).extend({
  region: AccountSchema.shape.region.nullish(),
  subdomain: SubdomainSchema.shape.subdomain.nullish(),
  legacyId: z.string().nullish(),
  logo: WorkspaceSchema.shape.logo,
  name: WorkspaceSchema.shape.name,
});

export interface IGetWorkspaceSwitcherResponseDto
  extends z.infer<typeof GetWorkspaceSwitcherResponseDtoSchema> {}

export const GetSuperuserWorkspaceSwitcherResponseDtoSchema = z.object(
  GetWorkspaceSwitcherResponseDtoSchema.shape,
);

export interface IGetSuperuserWorkspaceSwitcherResponseDto
  extends z.infer<typeof GetSuperuserWorkspaceSwitcherResponseDtoSchema> {}
