{
  "FUSIONAUTH_AUTHORITY": "https://auth.sitemate.com",
  "NODE_ENV": "production",
  "GLOBAL_API_URL": "https://api-global.sitemate.com/",
  "SITEMATE_API_URL": "https://api.sitemate.com/",
  "FUSIONAUTH_FLOWSITE_APPLICATION_ID": "ea0fd890-0f09-4649-a191-67e8fea140a1",
  "FUSIONAUTH_DASHPIVOT_APPLICATION_ID": "0d19fe48-a334-4dd6-9948-6e4304c6d91e",
  "DASHPIVOT_CALLBACK_PATH": "/oauth-callback/dashpivot",
  "XERO_CLIENT_PKCE": "0092D19F57FF4F7CA42FED88AF0C1FFB",
  "XERO_CALLBACK_PATH": "/oauth-callback/xero",
  "QUICKBOOKS_CLIENT_ID": "ABplgDmopnkPPJ1qSpgjCa86JQqToPoAWP9e0sj47VLhSaz3tb",
  "QUICKBOOKS_CALLBACK_PATH": "/oauth-callback/quickbooks",
  "MICROSOFT_CLIENT_ID": "3e3a8377-75e2-4494-b853-4b15aa65d8c2",
  "MICROSOFT_CALLBACK_PATH": "/oauth-callback/microsoft"
}
